$(document).ready(() => {
    const $menuBtn = $('.menu-button')
    const $menu = $('.menu-container')

    const speed = 500
    const easing = 'easeOutExpo'

    $menuBtn.on('click', () => {
        $menuBtn.animate({
            right: ['-25vw', easing]
        }, speed)
        $menu.animate({
            right: [0, easing]
        }, speed)
        $menu.css('box-shadow', '0 0 20px -5px rgba(0, 0, 0, 0.1)')
    })

    $('.close-menu').on('click', () => {
        $menuBtn.animate({
            right: ['3vw', easing]
        }, speed)
        $menu.animate({
            right: ['-35vw', easing]
        }, speed)
        $menu.css('box-shadow', 'none')
    })
})